import { render, staticRenderFns } from "./AgreementView.vue?vue&type=template&id=50422ee0&"
import script from "./AgreementView.vue?vue&type=script&lang=ts&"
export * from "./AgreementView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/filmination-frontend/filmination-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('50422ee0')) {
      api.createRecord('50422ee0', component.options)
    } else {
      api.reload('50422ee0', component.options)
    }
    module.hot.accept("./AgreementView.vue?vue&type=template&id=50422ee0&", function () {
      api.rerender('50422ee0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Administrators/AgreementView.vue"
export default component.exports
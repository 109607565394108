var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container mx-auto" }, [
    _c("div", { staticClass: "w-full" }, [
      _c(
        "div",
        { staticClass: "mb-4 bg-gray-200" },
        [
          _vm.pdfLatest
            ? _c("vue-pdf-embed", { attrs: { source: _vm.pdfLatest.file.url } })
            : _vm._e(),
        ],
        1
      ),
      _c("p", { staticClass: "h-8" }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }